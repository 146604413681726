/* social links horizontal, since only thing in footer */
.social-media-list li {
  display: inline;
  padding-right: 1em;
}

.social-media-list .svg-icon {
  padding-right: .1em;
}

/* http://david.elbe.me/jekyll/2015/06/20/how-to-link-to-next-and-previous-post-with-jekyll.html */
.rn-post-nav {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
}

.rn-post-nav a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.rn-post-nav .next {
  text-align: right;
}
